.WhyMe {
  text-align: center;
}

@media (min-width: 1200px) {
  .AIProjects {
    width: 50%;
  }
}

@media (max-width: 1199px) {
  .AIProjects {
    width: 90%;
  }
}
